import React from "react"
import { Script } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"

import '../styles/upload.css'

const Upload = () => {
  return (
    <>
      <Header />
      <Layout>
        <div className="upload_embed">
        <div className="upload_embed_container">
        <div id="mortgageautomator_loanform"></div>
        <Script src="https://www.mortgageautomator.com/maloader.js" defer />

        <Script
          dangerouslySetInnerHTML={{
            __html: `
        setTimeout(()=>{
            MortgageAutomatorLoader.register(function(){
                MortgageAutomator.ezloadLoanForm("10146")
            })
            MortgageAutomator.ezloadLoanForm("10146")
        },[3000])
        `,
          }}
        />
        </div>
        </div>
      </Layout>
    </>
  )
}

export default Upload
